import React, { useState } from 'react';
import { CheckboxField, TextField, FormField, Button } from '@periodica/ui-kit';
import type { ReviewFormArgs } from './types';
import { ReviewFormContainer } from './ReviewFormContainer';

import styles from './ClaimForm.module.scss';

export const CHECKBOXES = [
  { id: 'quality', label: 'Качество печати', checked: false },
  { id: 'delivery', label: 'Работа курьерской службы', checked: false },
  { id: 'pack', label: 'Упаковка и внешний вид заказа', checked: false },
];

export function ClaimForm({ order, vote, phone, fullname, email, onSubmit }: ReviewFormArgs) {
  const [comment, setComment] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItems((prevState) => ({ ...prevState, [e.target.value]: !e.target.checked }));
  };

  const selectedTags = Object.keys(selectedItems).filter((value) => !selectedItems[value]);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    onSubmit({
      vote,
      phone,
      fullname,
      email,
      order,
      comment,
      selectedTags,
    });
  };

  return (
    <ReviewFormContainer
      title="Расскажите, что случилось?"
      description="Расскажите, что случилось и испортило впечатление о заказе? Наш менеджер свяжется с вами
      в рабочее время (с 10:00 до 19:00) и поможет решить возникшую проблему."
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        <input type="hidden" name="vote" defaultValue={vote} />
        <input type="hidden" name="email" defaultValue={email} />
        <input type="hidden" defaultValue={order} name="order" />
        <input type="hidden" defaultValue={phone} name="phone" />
        <input type="hidden" defaultValue={fullname} name="fullname" />
        <div className={styles.checkboxes}>
          {CHECKBOXES.map(({ id, label }) => (
            <CheckboxField
              key={id}
              title={label}
              value={label}
              onChange={handleInputChange}
              checked={false}
              disabled={false}
            />
          ))}
        </div>

        <FormField label="Комментарий" name="comment">
          <TextField
            name="comment"
            tagElement="textarea"
            value={comment}
            onChange={setComment}
            rows={4}
            placeholder="Что случилось или как можем помочь?"
          />
        </FormField>
        <Button
          size="x2Large"
          type="submit"
          className={styles.buttonStyles}
          disabled={!(selectedTags.length || comment)}
        >
          Отправить
        </Button>
      </form>
    </ReviewFormContainer>
  );
}
