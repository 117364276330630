import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Loader } from '@periodica/ui-kit';
import { EmptyLayout, Paragraph as P, Link as A, Button } from '@periodica/ui-kit';
import Layout from '@components/Layouts/Layout';
import { OneColumn } from '@components/Layouts/OneColumn';
import { ClaimForm } from '@components/Forms/Feedback/ClaimForm';
import { PositiveReviewForm } from '@components/Forms/Feedback/PositiveReviewForm';
import SEO from '@components/Layouts/SEO';
import { APP_STORE_LINK } from '@consts/appsConst';
import { postFeedBack, type PostFeedBackArgs } from '../periodicaApi/feedBack';

import styles from './claim.module.scss';

const getOrderInfoFromBase64 = (base64string: string) => {
  try {
    return decodeURIComponent(escape(atob(base64string)));
  } catch (err) {
    return null;
  }
};

function FeedbackPage() {
  const [formData, setFormData] = useState({
    order: 0,
    vote: 0,
    email: '',
    phone: '',
    fullname: '',
  });

  const [formStatus, setFormStatus] = useState<
    'initialization' | 'idle' | 'submitting' | 'submitted' | 'error'
  >('initialization');

  useEffect(() => {
    try {
      const query = typeof window !== 'undefined' ? window.location.search.replace('?', '') : null;
      if (query) {
        const result = new URLSearchParams(window.location.search);
        const urlInfo = result.get('info');
        const urlStar = result.get('star');
        const data = JSON.parse(getOrderInfoFromBase64(urlInfo || '') || '');
        if (data) {
          setFormData({ ...data, vote: parseInt(urlStar || '1', 10) });
          setFormStatus('idle');
        } else {
          navigate('/404');
        }
      }
    } catch (e) {
      console.debug(e);
    }
  }, []);

  useEffect(() => {
    if (formStatus === 'submitted' || formStatus === 'error') {
      window.scrollTo(0, 0);
    }
  }, [formStatus]);

  const onSubmit = async (args: PostFeedBackArgs) => {
    setFormStatus('submitting');
    try {
      await postFeedBack(args);
      setFormStatus('submitted');
    } catch (e) {
      setFormStatus('error');
    }
  };

  const { order, vote, email, phone, fullname } = formData;

  if (formStatus === 'error') {
    return (
      <Layout>
        <OneColumn>
          <p className={styles.description}>Что-то пошло не так. Попробуйте обновить страницу.</p>
        </OneColumn>
      </Layout>
    );
  }

  if (formStatus === 'submitted') {
    return (
      <Layout>
        <OneColumn>
          <SEO
            title="Спасибо за ваш отзыв!"
            description="Мы обрабатываем каждый отзыв и учитываем ваши пожелания в работе."
          />
          <EmptyLayout
            title="Спасибо за ваш отзыв!"
            description={
              <P size="large">
                Мы обрабатываем каждый отзыв и&nbsp;учитываем ваши&nbsp;пожелания в&nbsp;работе.
              </P>
            }
            additionalInfo={
              vote >= 4 && (
                <P size="small">
                  Вы также можете оставить отзыв на&nbsp;
                  <A
                    href="https://irecommend.ru/srch?query=%D0%BF%D0%B5%D1%80%D0%B8%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    iRecommend.ru
                  </A>
                  . Если вы&nbsp;совершили заказ из&nbsp;мобильного приложения, пожалуйста, оцените
                  его в&nbsp;
                  <A href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                    App&nbsp;Store
                  </A>
                  .
                </P>
              )
            }
          >
            <Button size="xLarge" variant="secondary" asChild>
              <Link to="/">На главную</Link>
            </Button>
          </EmptyLayout>
        </OneColumn>
      </Layout>
    );
  }

  if (formStatus === 'idle') {
    return (
      <Layout>
        <SEO
          title="Оставьте свой отзыв о работе Периодики"
          description="Мы обрабатываем каждый отзыв и учитываем ваши пожелания в работе."
        />
        {vote < 4 ? (
          <ClaimForm
            onSubmit={onSubmit}
            fullname={fullname}
            email={email}
            phone={phone}
            vote={vote}
            order={order}
          />
        ) : (
          <PositiveReviewForm
            onSubmit={onSubmit}
            fullname={fullname}
            email={email}
            phone={phone}
            vote={vote}
            order={order}
          />
        )}
      </Layout>
    );
  }

  if (formStatus === 'initialization' || formStatus === 'submitting') {
    return <Loader />;
  }

  return null;
}

export default FeedbackPage;
